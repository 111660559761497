import React from "react";
import PropTypes from "prop-types";

const Spacer = ({ x = 0, y = 0 }) => {
  return <div style={{ marginTop: y, marginRight: x }} />;
};

Spacer.propTypes = {
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Spacer;
