import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Header from "./widgets/Header";
import Footer from "./widgets/Footer";
import { Toaster } from "sonner";
import { AnimatePresence, motion } from "framer-motion";
import Spacer from "./components/Spacer";
import Button from "./components/Button";
import Loading from "./widgets/Loading";

// Ленивая подгрузка остальных страниц
const Panel = lazy(() => import("./pages/Panel"));
const Plans = lazy(() => import("./pages/Plans"));
const Account = lazy(() => import("./pages/Account"));
const Topup = lazy(() => import("./pages/Topup"));
const Charge = lazy(() => import("./pages/Charge"));
const Admin = lazy(() => import("./pages/Admin"));
const ErrorPage = lazy(() => import("./pages/Error"));
const API = lazy(() => import("./pages/API"));
const RefInfo = lazy(() => import("./pages/RefInfo"));
const Redeem = lazy(() => import("./pages/Redeem"));

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");

  const customStyles = {
    content: {
      backgroundColor: "#171717",
      borderWidth: "1px",
      borderColor: "#ECE9E610",
      padding: "20px",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 999,
    },
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralId = params.get("r");

    const jwt = localStorage.getItem("jwt");
    const existingReferral = localStorage.getItem("referral");

    if (!jwt && referralId && !existingReferral) {
      localStorage.setItem("referral", referralId);
    }
  }, [location]);

  useEffect(() => {
    if (error) openModal();
    else closeModal();
    if (error.code === 403 || error.code === 401) {
      localStorage.removeItem("jwt");
      navigate("/login");
    }
  }, [error]);

  const openModal = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setError("");
  };

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Header setError={setError} />
        <main className="flex-grow">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/error/:error" element={<ErrorPage />} />
              <Route path="/" element={<Home setError={setError} />} />
              <Route path="/panel" element={<Panel setError={setError} />} />
              <Route path="/login" element={<Login setError={setError} />} />
              <Route path="/plans" element={<Plans setError={setError} />} />
              <Route path="/account" element={<Account setError={setError} />} />
              <Route path="/topup" element={<Topup setError={setError} />} />
              <Route path="/charge/:id" element={<Charge setError={setError} />} />
              <Route path="/account/api" element={<API setError={setError} />} />
              <Route path="/account/ref" element={<RefInfo setError={setError} />} />
              <Route path="/account/redeem" element={<Redeem setError={setError} />} />
              <Route path="/admin/:tab" element={<Admin />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<p>404</p>} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
        <Toaster
          toastOptions={{
            style: {
              backgroundColor: "#171717",
              border: "1px solid #ECE9E610",
              padding: "12px"
            },
          }} />
      </div>
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center drop-shadow-[0_35px_35px_rgba(0,0,0,0.5)]"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1.2 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-[#171717] border border-[#ECE9E610] rounded-lg"
              initial={{ y: "50%", opacity: 0 }}
              animate={{ y: "0%", opacity: 1 }}
              exit={{ y: "50%", opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={customStyles.content}
            >
              <div>
                <h2 className="text-2xl font-bold text-center">An Error Occurred!</h2>
                <Spacer y={40} />
                <p className="text-center text-rose-400">
                  {error.msg}
                </p>
                <Spacer y={20} />
                <div className="flex flex-row gap-3 justify-center items-center">
                  <Button onClick={closeModal}>OK</Button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default App;
