import axios from "axios";
import { endpoint } from "@assets/endpoint.js"
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default async function upgrade(plan_name) {
  try {
    let response = await axios.post(
      `${endpoint}/user/upgrade`, {
      plan_name
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`
      }
    }
    );
    toast(
      <div className="">
        <p className="text-lime-400 text-xl font-bold">
          {response.data.message}
        </p>
      </div>,
      {
        duration: 3000,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    console.log(err.response);
    toast(
      <div className="">
        <p className="text-rose-600 text-xl font-bold">
          {err.response.data.message}
        </p>
      </div>,
      {
        duration: 2500,
      }
    );
    throw err
  }
}
