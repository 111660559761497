import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { motion, AnimatePresence } from "framer-motion"; // Импорт framer-motion

import home from "@assets/Home.svg";
import filter from "@assets/Filter.svg";
import star from "@assets/Star.svg";
import profile from "@assets/profile.svg";
import burgerIcon from "@assets/burger.svg"; // Иконка для кнопки меню
import close from "@assets/close.svg";

import me from "../api/user/me";

import Button from "../components/Button";
import Spacer from "../components/Spacer";
import { Link, useLocation } from "react-router-dom"; // Добавляем useLocation

import { useNavigate } from "react-router-dom";

import useLocalStorage from "react-use-localstorage";

function Header({ setError }) {
  const navigate = useNavigate();
  const location = useLocation(); // Получаем текущий путь

  const [jwt, setJwt] = useLocalStorage("jwt", localStorage.getItem("jwt"));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);
  const [menuOpen, setMenuOpen] = useState(false); // Состояние для открытия/закрытия меню

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1050);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen); // Переключаем состояние меню
  };

  const isActive = (path) => location.pathname === path; // Проверяем, активен ли путь

  return (
    <header className="relative bg-[#171717] flex items-center justify-start px-4 sm:px-8 md:px-16 py-6 mb-[48px] z-[49]">
      <div onClick={() => navigate("/")} className="hidden md:block cursor-pointer">
        <h1 className="text-[23px] text-white">OVERLOAD</h1>
      </div>
      <nav className="flex gap-4 sm:gap-8 md:gap-12 ml-4 sm:ml-8 md:ml-12">
        <NavItem icon={home} to="/" label="Home" isActive={isActive("/")} />
        <NavItem icon={filter} to="/panel" label="Panel" isActive={isActive("/panel")} />
        <NavItem icon={star} to="/plans" label="Plans" isActive={isActive("/plans")} />
      </nav>
      {isMobile ? (
        <>
          <button onClick={handleMenuToggle} className="ml-auto">
            <img src={burgerIcon} alt="Menu" />
          </button>
          <AnimatePresence>
            {menuOpen && (
              <>
                {/* Затемняющий фон */}
                <motion.div
                  className="fixed inset-0 bg-black z-10"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.7 }} // Более сильное затемнение
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }} // Плавность анимации
                  onClick={() => setMenuOpen(false)} // Закрываем меню при клике на фон
                />
                {/* Элемент с размытие фона */}
                <motion.div
                  className="fixed inset-0 z-0"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }} // Показываем элемент
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }} // Плавность анимации
                  style={{ backdropFilter: "blur(10px)" }} // Эффект размытия
                />
              </>
            )}
          </AnimatePresence>
          <Menu
            right
            isOpen={menuOpen}
            onClose={() => setMenuOpen(false)}
            customBurgerIcon={false} // Чтобы не показывать встроенную иконку
            className="bg-black mt-[-38px] pl-8"
          >
            <div className="pt-[40px]"></div>
            <button className="ml-auto mr-[40px]" onClick={() => setMenuOpen(false)}><img src={close} /></button>
            <Spacer y={40} />
            {jwt && <NavItem setMenuOpen={setMenuOpen} isMobile icon={profile} to="/account" label="Profile" isActive={isActive("/account")} />}
            {jwt ? (
              <div className="mt-4">
                <Subscription setMenuOpen={setMenuOpen} setError={setError} />
              </div>
            ) : <Button onClick={() => { setMenuOpen(false); navigate("/login") }} size="sm" className="font-medium text-lg mt-4">
              Log in
            </Button>}

          </Menu>
        </>
      ) : jwt ? (
        <div className="ml-auto flex flex-row gap-2 items-center">
          <Subscription setError={setError} />
          <Spacer x={10} />
          <Link to="/account">
            <img src={profile} alt="Profile" />
          </Link>
        </div>
      ) : (
        <Button onClick={() => navigate("/login")} size="md" variant="invisible" className="ml-auto font-medium text-lg">
          Log in
        </Button>
      )}
    </header>
  );
}

function Subscription({ setMenuOpen = () => { ;; }, setError = () => { } }) {
  const navigate = useNavigate();
  const [jwt, setJwt] = useLocalStorage("jwt", localStorage.getItem("jwt"));
  const [info, setInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        if (!jwt || !localStorage.getItem("jwt")) return navigate("/login")
        const response = await me(); // Делаем запрос на данные о пользователе
        setInfo(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setError({ msg: err.response.data.message, code: err.response.status })
      }
    }

    fetchData();
  }, []);

  const go = (path) => {
    setMenuOpen(false);
    navigate(path);
  };

  if (isLoading) return null;
  if (!localStorage.getItem("jwt")) return null

  return (
    <>
      <div className="bg-[#46464620] p-[15px] rounded-[12px] flex flex-row items-center gap-4">
        <p><span className="font-bold">{info.balance}</span><span className="">$</span></p>
        <Button onClick={() => go("/topup")} size="sm" className="rounded-[6px] text-[12px]">
          Topup
        </Button>
      </div>
      <div className="bg-[#46464620] p-[15px] rounded-[12px] flex flex-row items-center gap-4">
        <p className="text-secondary text-sm">
          {info.active ? <span>Your plan is active.<br /><span className="text-white">{info.left_or_ago_str} left.</span></span> : ""}
          {!info.active && info.left_or_ago_str == null ? <span className="text-white">You don't have any plan.</span> : ""}
          {!info.active && info.left_or_ago_str ? <span className="text-white">Your plan expired {info.left_or_ago_str} ago.</span> : ""}
        </p>
        {!info.active && info.left_or_ago_str == null ? (
          <Button onClick={() => go("/plans")} size="sm" className="rounded-[6px] text-[12px]">
            Buy
          </Button>
        ) : !info.active && info.left_or_ago_str ? (
          <Button onClick={() => go("/plans")} size="sm" className="rounded-[6px] text-[12px]">
            Buy
          </Button>
        ) : null}
      </div>
    </>
  );
}

function NavItem({ icon, label, to, isActive, isMobile = false, setMenuOpen = () => { ;; } }) {
  const navigate = useNavigate();

  const go = (to) => {
    setMenuOpen(false);
    navigate(to);
  };

  return (
    <motion.div
      className="cursor-pointer"
      onClick={() => go(to)}
      whileHover={{ color: "#ffffff", scale: 1.05 }} // Hover эффект с увеличением
      transition={{ duration: 0.1 }} // Плавная анимация
    >
      <div className="flex items-center gap-2 cursor-pointer">
        <img width={13.5} height={13.5} src={icon} alt={label} />
        <p className={`${!isMobile && isActive ? "" : !isMobile ? "text-secondary" : ""} ${isMobile ? "text-2xl" : ""}`}>{label}</p>
      </div>
    </motion.div>
  );
}

export default Header;
