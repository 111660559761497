import axios from "axios";
import { endpoint } from "@assets/endpoint.js"
import { toast } from "sonner";

export default async function login(turnstile, token) {
  try {
    let response = await axios.post(
      `${endpoint}/auth/login`,
      {
        turnstile,
        token
      },
    );
    return response.data;
  } catch (err) {
    console.log(err.data)
    toast(
      <div className="">
        <p className="text-[#8C8C8C]">
          Login failed
        </p>
      </div>,
      {
        duration: 1000,
      }
    );
    console.log(err.response);
  }
}
