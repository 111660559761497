import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import copyIcon from "@assets/copy.svg"; // Замените на путь к вашей иконке копирования
import check from "@assets/check.svg";
import { Toaster, toast } from "sonner";

function Snippet({ text, className, isBig = false }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        // Успешное копирование
        setIsCopied(true);
        toast(
          <div className="">
            <p className="text-[#8C8C8C]">Copied successfully!</p>
          </div>,
          {
            duration: 1000,
          }
        );

        // Сброс состояния после анимации
        setTimeout(() => {
          setIsCopied(false);
        }, 1000); // Время ожидания перед сбросом
      },
      (err) => {
        // Обработка ошибок
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const containerStyles =
    "relative px-[16px] py-[20px] bg-white/0 border-[1px] border-[#ECE9E610] rounded-[12px] color-secondary text-white";
  const textStyles = "overflow-hidden text-ellipsis text-tiny mr-8";
  const buttonStyles =
    "absolute top-1/2 right-4 transform -translate-y-1/2 bg-transparent border-none cursor-pointer";

  return (
    <div className={containerStyles + " " + className}>
      <div className={textStyles} contentEditable={false}>
        <code className={isBig ? "text-md sm:text-lg" : ""}>{text}</code>
      </div>
      <button type="button" onClick={handleCopy} className={buttonStyles}>
        <AnimatePresence>
          {isCopied ? (
            <motion.img
              key="check"
              src={check}
              alt="Copied"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.3 }}
            />
          ) : (
            <motion.img
              key="copy"
              src={copyIcon}
              alt="Copy to clipboard"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.3 }}
            />
          )}
        </AnimatePresence>
      </button>
      <Toaster
        toastOptions={{
          style: {
            backgroundColor: "#ECE9E610",
            border: "1px solid #ECE9E610",
          },
        }}
      />
    </div>
  );
}

export default Snippet;
