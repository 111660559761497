import React, { useState } from "react";
import eye from "@assets/eye.svg";
import eyeclosed from "@assets/eyeclosed.svg";

function InputCode({ onChange, value, className }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const baseStyles =
    "px-[16px] py-[20px] w-[330px] bg-white/0 border-[1px] border-[#ECE9E610] rounded-[12px] color-secondary transition-border-color transition-duration-300 transition-ease focus:border-white/45 text-white"; // Добавляем стиль для фокуса
  const inputType = showPassword ? "text" : "password";

  return (
    <div className="relative">
      <input
        type={inputType}
        className={`${baseStyles} ${className}`}
        onChange={onChange}
        value={value}
        style={{ outline: "none" }}
      />
      <button
        type="button"
        onClick={handleTogglePassword}
        className="absolute inset-y-0 right-0 flex items-center justify-center pr-3"
        style={{ height: "100%" }}
      >
        <img
          src={showPassword ? eyeclosed : eye}
          alt={showPassword ? "Hide password" : "Show password"}
          className="w-6 h-6 cursor-pointer"
          style={{ transform: showPassword ? "rotate(180deg)" : "none" }}
        />
      </button>
    </div>
  );
}

export default InputCode;
