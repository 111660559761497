import axios from "axios";
import {endpoint} from "@assets/endpoint.js"
import { useNavigate } from "react-router-dom";

export default async function get() {
  try {
    let response = await axios.get(
      `${endpoint}/plans`,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err
  }
}
