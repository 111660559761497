import axios from "axios";
import {endpoint} from "@assets/endpoint.js"
import { useNavigate } from "react-router-dom";

export default async function register(token) {
  try {
    let response = await axios.post(
      `${endpoint}/auth/register`,
      {
        turnstile: token,
        invited_by: parseInt(localStorage.getItem("referral")) || 0
      },
    );
    return response.data;
  } catch (err) {
    console.error(err);
    console.log(err.response);
    throw err
  }
}
