import telegram from "@assets/Telegram.svg";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="relative flex flex-col md:flex-row items-center justify-between px-4 md:px-8 py-8 md:py-24">
      <h2 onClick={() => navigate("/")} className="cursor-pointer text-2xl md:text-[23px] text-secondary mb-4 md:mb-0">
        OVERLOAD
      </h2>

      {/* Центрируем ссылки на больших экранах */}
      <nav className="flex justify-center items-center mb-4 md:mb-0 md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
        <p onClick={() => navigate("/")} className="text-secondary mx-2 md:mx-4 cursor-pointer">
          Home
        </p>
        <p onClick={() => navigate("/panel")} className="text-secondary mx-2 md:mx-4 cursor-pointer">
          Panel
        </p>
        <p onClick={() => navigate("/plans")} className="text-secondary mx-2 md:mx-4 cursor-pointer">
          Plans
        </p>
        <div className="p-1 border-[1px] border-yellow-600/45 rounded-[12px]">
          <p onClick={() => navigate("/account/ref")} className=" mx-2 md:mx-4 cursor-pointer">
            Earn
          </p>
        </div>
      </nav>

      <Link to="https://t.me/OverloadSupporttBot" target="__blank">
        <img src={telegram} alt="Telegram" className="w-8 h-8 md:w-auto md:h-auto" />
      </Link>
    </footer>
  );
}

export default Footer;
