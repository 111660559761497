import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Plans from "./Plans";
import Spacer from "../components/Spacer";
import arrow from "@assets/arrow.svg";
import { useNavigate } from "react-router-dom";
import bg1 from "@assets/5V7sPQR.webp"
import bg2 from "@assets/QMbfqUA.webp"
import bg3 from "@assets/bg3.png"
import bg4 from "@assets/tPV8wmG.webp"
import { getOpenStats } from "../api/stats/open";

function Home() {
  const navigate = useNavigate();

  const textShadow = `
    0 0 10px rgba(255, 255, 255, 0.8),
    0 0 20px rgba(255, 255, 255, 0.7),
    0 0 30px rgba(255, 255, 255, 0.6),
    0 0 40px rgba(255, 255, 255, 0.5)
  `;

  const [currentIndex, setCurrentIndex] = useState(0);
  const timerIdRef = useRef(null);
  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const blocks = [
    {
      number: "01",
      title: "Real traffic",
      description:
        "Simulate real-world traffic and stress test your system with our advanced load testing service. Identify performance bottlenecks and optimize your infrastructure for maximum efficiency.",
    },
    {
      number: "02",
      title: "Botnet Included",
      description:
        "Our stresser includes RAW from our IoT Botnet, as well as Spoofed/Proxied traffic. This ensures high reliability and throughput, providing our customers with high quality service.",
    },
    {
      number: "03",
      title: "Best booter",
      description:
        "Our Layer 7 consists of very powerful dedicated servers with a dedicated network. We use only our own methods and provide clients with high-quality strength to test resources for resistance to attacks.",
    },
  ];

  const faq = [
    { question: "How high is your power?", answer: "We have one of the leading positions in terms of attack power, as you can see on many forums and monitoring." },
    { question: "What is this for?", answer: "You can test your equipment for resistance to the most powerful attacks that exist at the moment." },
    { question: "What types of workloads can your stress testing service simulate?", answer: "Our service can simulate a wide range of workloads, including HTTP, HTTPS, WebSockets, and TCP/UDP traffic, L4/L7" },
    { question: "How much does stress testing cost?", answer: "Our pricing is competitive and flexible, depending on the scope and complexity of the test. We offer various packages and custom quotes to fit your budget and needs." },
  ];

  const [totalAttacks, setTotalAttacks] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        setTotalAttacks((await getOpenStats())?.total_attacks || "4 MILLION");
      } catch (err) {
        setTotalAttacks("4 MILLION")
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    startAutoSlide();

    return () => clearInterval(timerIdRef.current);
  }, []);

  const startAutoSlide = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % blocks.length);
    }, 5000);
  };

  const handleTileClick = (index) => {
    setCurrentIndex(index);
    startAutoSlide();
  };
 
  const handleFAQClick = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  return (
    <>
      <main
        className="relative flex justify-center items-center bg-center bg-cover mt-[-20px]"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div
          className="absolute inset-0 bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${bg2})`, backgroundSize: 'contain' }}
        ></div>

        <h1 className="text-center text-[51px] md:text-[128px] text-white font-bold relative py-10">
          TOP #1{" "}
          <span className="">
            <span
              className=""
              style={{ textShadow }}
            >
              IP
            </span>
          </span>
          <br />
          STRESSER
        </h1>
      </main>

      <section className="flex items-center justify-center">
        <div className="inline-flex flex-col lg:flex-row gap-4 sm:gap-12 items-center justify-center py-[50px] lg:py-[19px] px-[32px] lg:px-[128px] rounded-full border-[1px] border-[#ECE9E610] bg-[#FFFFFF04]">
          <div className="text-center">
            <h2 className="text-[24px] lg:text-[32px]">7.5M RPS</h2>
            <p className="text-secondary">Full L7 Capacity</p>
          </div>
          <div className="hidden lg:block w-[1px] h-full bg-gradient-to-b from-transparent via-[#252524] to-transparent"></div>

          <div className="text-center">
            <h2 className="text-[24px] lg:text-[32px]">1.5 TB</h2>
            <p className="text-secondary">Full L4 Capacity</p>
          </div>
          <div className="hidden lg:block w-[1px] h-full bg-gradient-to-b from-transparent via-[#252524] to-transparent"></div>

          <div className="text-center">
            <h2 className="text-[24px] lg:text-[32px]">{totalAttacks}</h2>
            <p className="text-secondary">Attacks in all Time</p>
          </div>
          <div className="hidden lg:block w-[1px] h-full bg-gradient-to-b from-transparent via-[#252524] to-transparent"></div>

          <div className="text-center">
            <h2 className="text-[24px] lg:text-[32px]">CLOUDFLARE</h2>
            <p className="text-secondary">Bypass L7 Methods</p>
          </div>
          <div className="hidden lg:block w-[1px] h-full bg-gradient-to-b from-transparent via-[#252524] to-transparent"></div>

          <div className="text-center">
            <h2 className="text-[24px] lg:text-[32px]">OVH</h2>
            <p className="text-secondary">Bypass L4 Methods</p>
          </div>
        </div>
      </section>

      <section className="mt-[100px] flex flex-col justify-center items-center">
        <div className="relative h-auto flex justify-center items-center min-h-[400px] px-4 lg:px-0">

          <AnimatePresence>
            {blocks.map((block, index) => (
              index === currentIndex && (
                <motion.div
                  key={block.number}
                  className="absolute flex flex-col justify-center items-center lg:flex-row text-center lg:text-left"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                >
                  <img src={bg3} width="712" height="712" className="w-[100px] lg:w-auto" alt="Image" />
                  <div className="flex flex-col gap-4 lg:gap-[50px] lg:ml-6">
                    <h2 className="text-[#FFFFFF] opacity-[12.5%] text-[64px] lg:text-[128px]">{block.number}</h2>
                    <h2 className="text-[24px] lg:text-[32px]">{block.title}</h2>
                    <p className="text-secondary text-[14px] lg:w-[20vw] sm:w-[90vw] w-[90vw]">{block.description}</p>
                  </div>
                </motion.div>
              )
            ))}
          </AnimatePresence>
        </div>

        <div className="flex gap-4 mt-12">
          {blocks.map((block, index) => (
            <button
              key={index}
              onClick={() => handleTileClick(index)}
              className={`w-[40px] h-[8px] rounded-full transition-all duration-300 ${index === currentIndex ? "bg-white scale-110" : "bg-gray-400"
                }`}
            />
          ))}
        </div>

        <div className="mt-[96px] lg:mt-[192px]">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-evenly items-center lg:gap-6 sm:gap-2">
            <img
              className="mb-6 lg:mb-0 w-[250px] md:w-[300px] lg:w-auto"
              src={bg4}
              width="910"
              height="512"
              alt="Dashboard"
            />
            <div className="flex flex-col gap-[16px] text-center lg:text-left">
              <h2 className="text-secondary text-[22px] md:text-[24px] lg:text-[30px]">
                Welcome to Your Dashboard
              </h2>
              <p className="text-[#D2D2D2] text-[12px] md:text-[16px] lg:w-[460px]">
                Here, you can control everything you want. Manage your tests and access our unique features.
              </p>
              <button
                onClick={() => navigate("/panel")}
                className="mt-[30px] px-6 py-3 border-[1px] border-[#ECE9E610] bg-[#FFFFFF04] rounded-full text-white"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </section>
      <Spacer y={150} />
      <Plans homepage={true} />
      <div className="flex-grow flex justify-center mb-4 md:mb-0 cursor-pointer">
        <p
          onClick={() => navigate("/plans")}
          className="text-center text-[32px] md:text-[32px] text-white font-bold relative hover:drop-shadow-text p-4 transition-transform duration-300 ease-in-out transform hover:scale-110"
        >
          And {Plans({ returnNotLandingLength: true })} more plans...
        </p>
      </div>
      <section className="w-[90vw] m-0 m-auto mt-[100px]">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {faq.map((item, index) => (
            <div key={index} className="flex flex-col">
              <div
                className={`relative p-8 bg-[#131313] rounded-[65px] border-[#ECE9E610] border-[1px] flex justify-between items-center cursor-pointer transition-all duration-300 ${openFAQIndex === index ? 'rounded-b-[0]' : ''
                  }`}
                onClick={() => handleFAQClick(index)}
              >
                <h2 className="text-white">{item.question}</h2>
                <motion.img
                  src={arrow}
                  className="w-6 h-6"
                  animate={{ rotate: openFAQIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                />
              </div>

              <AnimatePresence>
                {openFAQIndex === index && (
                  <motion.div
                    key="faq-content"
                    className="bg-[#13131380] rounded-b-[65px] border-[#ECE9E610] border-[1px] mt-[-1px] overflow-hidden"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0, transition: { duration: 0.3 } }}
                    transition={{ duration: 0.3 }}
                  >
                    <p className="p-8 text-[#D2D2D2]">{item.answer}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </section>

    </>
  );
}

export default Home;
