import { useState, useEffect } from "react";
import infoCircle from "@assets/Info Circle.svg";
import Spacer from "../components/Spacer";
import Button from "../components/Button";
import get from "../api/plan/get";
import { useNavigate } from "react-router-dom";
import upgrade from "../api/user/upgrade";
import { motion, AnimatePresence } from "framer-motion";
import useLocalStorage from "react-use-localstorage";

function Plans({ homepage = false, returnNotLandingLength = false, setError }) {
  const navigate = useNavigate();

  const customStyles = {
    content: {
      backgroundColor: "#171717",
      borderWidth: "1px",
      borderColor: "#ECE9E610",
      padding: "20px",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 999,
    },
  };

  const [jwt, setJwt] = useLocalStorage("jwt", localStorage.getItem("jwt"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [chosenPlan, setChosenPlan] = useState(null);
  const [hoveredPlan, setHoveredPlan] = useState(null); // Состояние для подсветки

  useEffect(() => {
    window.scrollTo(0, 0); // Скролл на начало страницы при изменении маршрута
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setPlans((await get()) || []);
      } catch (err) {
        setError({ msg: err.response.data.message, code: err.response.status })
      }
    }
    fetchData();
  }, []);

  if (returnNotLandingLength) return plans.filter((v) => !v.landing).length

  useEffect(() => {
    if (chosenPlan) {
      openModal();
    }
  }, [chosenPlan]);

  const openModal = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setChosenPlan(null);
    setIsModalOpen(false);
  };

  const buy = async () => {
    try {
      closeModal();
      await upgrade(chosenPlan.name);
      navigate("/account")
    } catch (err) {
      if (err.response.status == 403 || err.response.status == 401) {
        setError({ msg: err.response.data.message, code: err.response.status })
      }
    }
  };

  if (!plans.length) {
    return (
      <>
        {!homepage ? (
          <>
            <section className="flex flex-row gap-3 items-center rounded-[12px] p-[36px] bg-[#171717] border-[1px] border-[#ECE9E610]">
              <img width={24} height={24} src={infoCircle} alt="Info Icon" />
              <p className="text-secondary">
                Select your plan based on your needs to begin stress testing
                using Premium Power. Account activations are instant and fully
                automatic. For best effect we highly recommend buying multiple
                simultaneous attacks, power is heavily determined by how many
                simultaneous attacks your plan has, each one multiplies your
                power.
              </p>
            </section>
            <Spacer y={36} />
          </>
        ) : (
          <></>
        )}
        <div className="w-[75vw] m-0 m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {[1, 2, 3, 4, 5, 6].map((it, i) => (
            <div className="animate-pulse" key={i}>
              <div className="rounded-[24px] bg-white/15 h-72 max-w-[550px]"></div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="w-[90%] mx-auto">
      {!homepage ? (
        <>
          <section className="flex flex-row gap-3 items-center rounded-[12px] p-[36px] bg-[#171717] border-[1px] border-[#ECE9E610]">
            <img width={24} height={24} src={infoCircle} alt="Info Icon" />
            <p className="text-secondary">
              Select your plan based on your needs to begin stress testing using
              Premium Power. Account activations are instant and fully
              automatic. For best effect we highly recommend buying multiple
              simultaneous attacks, power is heavily determined by how many
              simultaneous attacks your plan has, each one multiplies your
              power.
            </p>
          </section>
          <Spacer y={36} />
        </>
      ) : (
        <></>
      )}
      <section className="2xl:max-w-[50vw] xl:max-w-[70vw] md:max-w-[90vw] m-0 m-auto mx-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {(!homepage ? plans : plans.filter((v) => v.landing)).map((plan) => (
          <div
            key={plan.name}
            className={`px-[36px] py-[48px] flex flex-col justify-between items-center bg-[#171717] rounded-[12px] border-[1px] border-[#ECE9E610] w-full h-full min-h-[480px] transition-transform duration-200 ${hoveredPlan === plan.name ? "scale-105" : ""}`} // добавляем эффект наведения
            onMouseEnter={() => setHoveredPlan(plan.name)}
            onMouseLeave={() => setHoveredPlan(null)}
          >
            <div className="flex flex-col items-center">
              <h2 className={`text-[36px] ${hoveredPlan == plan.name ? "drop-shadow-text" : ""}`}>{plan.display_name}</h2>
              <Spacer y={14} />
              <div className="flex items-center w-full">
                <div className="flex-grow h-px bg-secondary"></div>
                <p className="px-4 text-secondary text-[16px] font-bold">
                  Advantages
                </p>
                <div className="flex-grow h-px bg-secondary"></div>
              </div>
              <Spacer y={24} />
              <div className="flex flex-col gap-[28px]">
                <p className="text-center text-[#828180] text-[18px] font-bold">
                  {plan.maxtime} s{" "}
                  <span className="text-secondary">Max Attack Time</span>
                </p>
                <p className="text-center text-[#828180] text-[18px] font-bold">
                  {plan.concurrents} Concurrent Attacks
                </p>
                <p className="text-center text-secondary text-[18px] font-bold">
                  {plan.api ? "API Included" : "API Excluded"}
                </p>
                <p className="text-center text-secondary text-[18px] font-bold">
                  Unlimited Daily Attacks
                </p>
                <p className="text-center text-secondary text-[18px] font-bold">
                  For <span className="text-[#828180]">{plan.days} Days</span>
                </p>
              </div>
            </div>
            <Spacer y={24} />
            <Button
              className="w-full font-bold text-[20px]"
              onClick={
                !homepage ? () => setChosenPlan(plan) : () => navigate("/plans")
              }
            >
              Buy for {plan.price_usd}$
            </Button>
          </div>
        ))}
      </section>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center drop-shadow-[0_35px_35px_rgba(0,0,0,0.5)]"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1.2 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-[#171717] border border-[#ECE9E610] rounded-lg"
              initial={{ y: "50%", opacity: 0 }}
              animate={{ y: "0%", opacity: 1 }}
              exit={{ y: "50%", opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={customStyles.content}
            >
              <div>
                <h2 className="text-2xl font-bold text-center">Caution</h2>
                <Spacer y={40} />
                <p className="text-center">
                  Are you sure you want to buy {chosenPlan.display_name}?
                </p>
                <Spacer y={20} />
                <div className="flex flex-row gap-3 justify-center items-center">
                  <Button size="sm" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={jwt ? () => buy() : navigate("/login")}
                  >
                    Buy for {chosenPlan.price_usd}$
                  </Button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Plans;
