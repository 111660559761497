import React from "react";
import { motion } from "framer-motion";

function Button({
  onClick,
  variant = "primary",
  className = "",
  children,
  style,
  disabled = false,
  size = "md",
}) {
  let baseStyles = ``;

  const variantStyles = {
    primary: "bg-white",
    secondary: "text-white/75 bg-[#46464610]",
    invisible: "bg-transparent text-white",
    danger: "bg-rose-800 text-white",
    dark: "bg-secondary/15 text-white"
  };

  const sizes = {
    sm: "py-[6px] px-[8px] rounded-[12px] text-[12px]",
    normal: "py-[16px] px-[36px] rounded-[12px]",
    md: "py-[24px] w-[330px] rounded-[12px]",
  };

  const buttonStyles = `${baseStyles} ${variantStyles[variant] || variantStyles.primary
    } ${sizes[size]} ${className}`;

  return (
    <motion.button
      whileHover={{ scale: 1.05 }} 
      whileTap={{ scale: 0.95 }}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyles}
      style={style}
    >
      {children}
    </motion.button>
  );
}

export default Button;
