import axios from "axios";
import {endpoint} from "@assets/endpoint.js"
import { useNavigate } from "react-router-dom";

async function getOpenStats() {
  try {
    let response = await axios.get(
      `${endpoint}/openstats/get`,
    {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err
  }
}

export {
  getOpenStats,
} 
