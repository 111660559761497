import axios from "axios";
import {endpoint} from "@assets/endpoint.js"
import { useNavigate } from "react-router-dom";

export default async function me() {
  try {
    let response = await axios.get(
      `${endpoint}/user/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    console.log(err.response);
    throw err
  }
}
